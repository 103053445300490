import React from 'react';
import { StaticQuery, graphql } from 'gatsby'
import ProductListItem from '../ProductListItem'
import '../../Products/ProductList/ProductList.scss';
const ProductCategoryPass = (props) => {
  const { id, data } = props
  const { productCategories } = data
  const theCategory = productCategories.nodes.filter(cat => cat.wordpress_parent === parseInt(id))
  return (
    <div className="category-list">
      <section className="product-list">
        <div className="outer nopad">
          <div className="wrap">
            <div className="inner">
              <div className="list">
                {theCategory.map((slide, inedx) => {
                  return (
                    <ProductListItem key={inedx} data={slide} />
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};



export const ProductCategoryList = (props) => (
  <StaticQuery
    query={graphql`
        query {
          productCategories: allWordpressWpProductCategories(
            filter: { 
              count: { gt: 0 } 
            }
            sort: {order: ASC, fields: ord}
          ) {
            nodes {
              title:name
              description
              wordpress_id
              wordpress_parent
              path
              slug
              excerpt:description
              count
              acf {
                displayTitle
                megaMenuDesc
                menuTitle
                featured_image {
                  localFile {
                    publicURL
                    childImageSharp {
                      fluid(maxWidth: 320, quality: 70) {
                        ... GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
    render={data => <ProductCategoryPass data={data} {...props} />}
  />
)
